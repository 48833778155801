import Vue from 'vue'

function addTooltip(el, text) {
    const tooltip = Vue.extend({
        template: `<el-tooltip content="${text}" placement="top">
                        <div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap">${text}</div>
                    </el-tooltip>`
    })
    const tooltipInstance = new tooltip()
    const child = tooltipInstance.$mount().$el
    el.innerHTML = ''
    el.appendChild(child)
}

function getTextWidth(text) {
    const div = document.createElement('div')
    div.style.display = 'inline-block'
    div.innerText = text
    document.body.appendChild(div)
    const width = div.clientWidth
    document.body.removeChild(div)
    return width
}

function handle(el, text) {
    if (getTextWidth(text || el.innerText) > el.clientWidth) {
        addTooltip(el, text || el.innerText)
    } else {
        el.innerText = text || el.innerText
    }
}

Vue.directive('tooltip', {
    inserted: function (el, binding) {
        el.style.whiteSpace = 'nowrap'
        handle(el, binding.value)
    },
    update: function (el, binding) {
        handle(el, binding.value)
    }
})
